<template>
  <div>
    <Table
      :isNecessary="1"
      :list="necessaryList"
      @set-list="setList"
      :readOnly="readOnly"
    />
    <Table
      :isNecessary="0"
      :list="list"
      @set-list="setList"
      :readOnly="readOnly"
    />
    <Table
      :isNecessary="2"
      :list="otherList"
      @set-list="setList"
      :readOnly="readOnly"
    />
  </div>
</template>

<script>
import Table from "./table";
export default {
  props: {
    // 必要
    necessaryList: {
      type: Array,
      default: () => [],
    },
    // 非必要
    list: {
      type: Array,
      default: () => [],
    },
    otherList: {
      type: Array,
      default: () => [],
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Table,
  },

  methods: {
    setList(payload) {
      this.$emit("setList", payload);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 16px;
  .title {
    font-size: 16px;
  }
}
.field {
  margin-bottom: 16px;
}
</style>

