<template>
  <div style="margin-bottom: 24px">
    <a-table :data-source="list" bordered :pagination="false">
      <template slot="title">
        <div class="between">
          <div class="title">
            <span v-if="isNecessary === 1"> 设计类-必要分包 </span>
            <span v-if="isNecessary === 0"> 设计类-非必要分包 </span>
            <span v-if="isNecessary === 2"> 总承包类分包 </span>
          </div>
          <a-button icon="plus" type="primary" @click="add" v-if="!readOnly"
            >添加</a-button
          >
        </div>
      </template>

      <a-table-column key="name" title="分包名称" data-index="name" />

      <a-table-column key="type" title="分包类型">
        <template slot-scope="text">
          <div class="left">
            <DataDictFinder
              v-if="isNecessary === 1"
              dictType="subType"
              :dictValue="text.type"
            />
            <DataDictFinder
              v-if="isNecessary === 0"
              dictType="notNeedSubType"
              :dictValue="text.type"
            />
            <DataDictFinder
              v-if="isNecessary === 2"
              dictType="generalSubType"
              :dictValue="text.type"
            />
            <template v-if="text.major">
              <span> - </span>
              <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
            </template>
          </div>
        </template>
      </a-table-column>
      <a-table-column
        key="companyName"
        title="公司"
        data-index="companyName"
        width="240px"
        ellipsis="true"
      ></a-table-column>

      <a-table-column
        key="planAmount"
        title="预计分包金额(万元)"
        data-index="planAmount"
        align="right"
        width="130px"
      ></a-table-column>
      <a-table-column
        key="status"
        title="分包状态"
        data-index="status"
        align="center"
      >
        <template slot-scope="status">
          <div>
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="status"
              iconType="badge"
            />
          </div>
        </template>
      </a-table-column>
      <a-table-column
        key="remarks"
        title="备注"
        data-index="remarks"
        ellipsis="true"
      ></a-table-column>

      <a-table-column key="files" title="附件">
        <template slot-scope="text">
          <span v-if="text.fileList">
            <a
              v-for="(item, index) in text.fileList"
              :key="index"
              :href="item.completePath"
              download
              target="_blank"
            >
              {{ item.name }}
            </a>
          </span>
        </template>
      </a-table-column>

      <a-table-column
        key="control"
        title="操作"
        align="center"
        v-if="!readOnly"
      >
        <template slot-scope="text, record, index">
          <a-space>
            <a
              href="#"
              v-if="!disabled"
              @click.prevent="edit(text, record, index)"
            >
              编辑
            </a>
            <a
              href="#"
              v-if="!disabled"
              class="danger"
              @click.prevent="remove(text, record, index)"
            >
              删除
            </a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      v-model="visible"
      :title="status === 'add' ? '新增分包' : '编辑分包'"
      :zIndex="99"
    >
      <template slot="footer">
        <a-button key="back" @click="visible = false"> 取消 </a-button>
        <a-button key="submit" type="primary" @click="save"> 保存 </a-button>
      </template>

      <a-form-model
        ref="form"
        :rules="rules"
        :model="form"
        :colon="false"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
      <a-form-model-item v-if="status === 'edit'" label="类型">
          <a-radio-group v-model="toggleNecessary" @change="onChange">
            <a-radio
              v-for="item in [
                {
                  name: '必要分包',
                  value: 1,
                },
                {
                  name: '非必要分包',
                  value: 0,
                },
                {
                  name: '总承包类分包',
                  value: 2,
                },
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="分包名称" prop="name">
          <a-input placeholder="请输入分包名称" v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item label="分包类型" prop="type">
          <a-select
            v-model="form.type"
            style="width: 100%"
            placeholder="请选择分包类型"
          >
            <template v-if="toggleNecessary === 1">
              <a-select-option
                v-for="item in isNecessaryTypeList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </template>
            <template v-if="toggleNecessary === 0">
              <a-select-option
                v-for="item in typeList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </template>
            <template v-if="toggleNecessary === 2">
              <a-select-option
                v-for="item in generalSubTypeList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          label="专业类型"
          v-if="form.type === 'JS3'"
          prop="major"
        >
          <a-select
            v-model="form.major"
            style="width: 100%"
            placeholder="请选择专业类型"
          >
            <template>
              <a-select-option
                v-for="item in majorTypes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </template>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <span class="required" slot="label">公司</span>

          <CompanySelector @change="(company) => (selectedCompany = company)">
            <a-button block style="text-align: left" class="ellipsis">
              <div
                class="left text-hidden selected"
                v-if="selectedCompany.name"
              >
                {{ selectedCompany.name }}
              </div>
              <div class="left placeholder" v-else>请选择公司</div>
            </a-button>
          </CompanySelector>
        </a-form-model-item>

        <a-form-model-item label="预计分包金额(万元)" prop="planAmount">
          <a-input-number
            :formatter="$inputNumberFormat"
            v-model="form.planAmount"
            placeholder="请输入分包金额"
            style="width: 100%"
          />
        </a-form-model-item>

        <a-form-model-item label="分包状态" prop="status">
          <a-radio-group v-model="form.status">
            <a-radio
              v-for="item in [
                {
                  name: '未就绪',
                  value: 'notReady',
                },
                {
                  name: '已签订',
                  value: 'signed',
                },
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="备注" prop="remarks">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-model="form.remarks"
            placeholder="请输入备注"
          />
        </a-form-model-item>

        <a-form-model-item label="附件">
          <FileUpload @uploaded="uploaded">
            <a-button type="primary" size="small">上传</a-button>
          </FileUpload>

          <a-table :data-source="fileList" bordered :pagination="false">
            <a-table-column title="附件名">
              <template slot-scope="text">
                <a :href="text.completePath" target="_blank" download>
                  {{ $getFileName(text.completePath) }}
                </a>
              </template>
            </a-table-column>
            <a-table-column title="操作" align="center" width="120px">
              <template slot-scope="text, record, index">
                <a href="#" class="danger" @click.prevent="removeFile(index)"
                  >删除</a
                >
              </template>
            </a-table-column>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileUpload from "@/components/file-upload";
import CompanySelector from "@/components/company-selector";

export default {
  components: {
    FileUpload,
    CompanySelector,
  },

  props: {
    isNecessary: Number,
    list: Array,

    disabled: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toggleNecessary: 0,
      visible: false,
      status: "add",
      activeIndex: -1, // 正在删除或编辑的记录
      rules: {
        name: [{ required: true, message: "请输入！", trigger: "blur" }],
        type: [{ required: true, message: "请选择！", trigger: "blur" }],
        planAmount: [{ required: true, message: "请输入！", trigger: "blur" }],
        status: [{ required: true, message: "请选择！", trigger: "blur" }],
        major: [{ required: true, message: "请选择！", trigger: "blur" }],
        remarks: [{ required: false }],
      },
      form: {},

      selectedCompany: {}, // 选中的公司

      fileList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    isNecessaryTypeList() {
      return this.findDataDict("subType");
    },
    typeList() {
      return this.findDataDict("notNeedSubType");
    },
    generalSubTypeList() {
      return this.findDataDict("generalSubType");
    },

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },

    booleanList() {
      return this.findDataDict("boolean");
    },

    statusList() {
      return this.findDataDict("subpackageStatus");
    },
  },

  methods: {
    onChange() {
      this.form.type = null;
      this.form.major = null;
    },
    // 上传附件
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },

    close() {
      this.visible = false;
      this.form = {};
      this.selectedCompany = {};
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.selectedCompany.id) {
            this.$message.error("请选择公司！");
            return;
          }

          if (this.status === "add") {
            this.$emit("set-list", {
              list: [
                ...this.list,
                {
                  ...this.form,
                  // isBearcost: this.isNecessary ? undefined : 1,
                  companyId: this.selectedCompany.id,
                  companyName: this.selectedCompany.name,
                  fileList: this.fileList,
                },
              ],
              isNecessary: this.isNecessary,
              toggleNecessary: this.toggleNecessary,
            });
            this.visible = false;
            this.form = {};
            this.selectedCompany = {};
            this.fileList = [];
          } else {
            const list = this.list;
            if(this.toggleNecessary !== this.isNecessary){
              list.splice(this.activeIndex, 1);
            }else{
              list.splice(this.activeIndex, 1, {
                ...this.form,
                // isBearcost: this.isNecessary ? undefined : 1,
                companyId: this.selectedCompany.id,
                companyName: this.selectedCompany.name,
                fileList: this.fileList,
              });
            }
            this.$emit("set-list", {
              list,
              isNecessary: this.isNecessary,
              toggleNecessary: this.toggleNecessary,
              row:{
                ...this.form,
                companyId: this.selectedCompany.id,
                companyName: this.selectedCompany.name,
                fileList: this.fileList,
              }
            });
            this.visible = false;
            this.form = {};
            this.selectedCompany = {};
            this.fileList = [];
            return;
          }
        } else {
          return false;
        }
      });
    },

    add() {
      this.status = "add";
      this.visible = true;
      this.toggleNecessary = JSON.parse(JSON.stringify(this.isNecessary));
    },
    edit(text, record, index) {
      console.log(record);
      this.activeIndex = index;
      this.status = "edit";
      this.form = JSON.parse(JSON.stringify(text));
      this.selectedCompany = {
        id: text.companyId,
        name: text.companyName,
      };
      this.fileList = text.fileList ?? [];
      this.visible = true;
      this.toggleNecessary = JSON.parse(JSON.stringify(this.isNecessary));
    },
    remove(text, record, index) {
      console.log(text, record);
      const list = this.list;
      list.splice(index, 1);

      this.$emit("set-list", {
        list,
        isNecessary: this.isNecessary,
      });
    },
  },
  mounted(){
    this.toggleNecessary = JSON.parse(JSON.stringify(this.isNecessary));
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}
</style>

